import { useSDK } from '@metamask/sdk-react'
import { toast, Toaster } from 'sonner'
import { Contract } from 'web3'
import "./App.css";
import { BTokenABI, BTokenAddress, LendPoolABI, LendPoolAddress, USDTABI, USDTAddress } from './contract';
import { useState } from 'react';

function App() {

	const { account, sdk, provider } = useSDK()

	const [depositNum, setDepositNum] = useState(0)
	const [withdrawNum, setWithdrawNum] = useState(0)

	const approve = async () => {
		if(!account || !provider) {
			toast.error('Not connected')
			return;
		}

		const contract = new Contract(USDTABI, USDTAddress)
		contract.setProvider(provider)

		console.log('approve num', BigInt(depositNum * 10 ** 18))

		const txData = contract.methods.approve(
			LendPoolAddress, BigInt(depositNum * 10 ** 18)
		).encodeABI()

		const res = await provider.request({
			method: 'eth_sendTransaction',
			params: [{
				from: account,
				to: USDTAddress,
				gas: '3000000',
				data: txData,
				value: 0,
			}]
		})

		console.log('approve', res)

		return res
	}


	const getMaxWithdraw = async () => {
		if(!account) {
			toast.error('Not connected')
		}else {
			const contract = new Contract(BTokenABI, BTokenAddress)
			contract.setProvider(provider)

			const res: bigint = await contract.methods.balanceOf(account).call({
				from: account
			})

			console.log('max withdraw', res)

			return res
		}
	}


	const deposit = async () => {
		if(!account || !provider) {
			toast.error('Not connected')

			return;
		}else {
			try {
				if(Number.isNaN(depositNum)) {
					toast.error('Invalid number')
					return;
				}

				const canDeposit = await approve()

				if(!canDeposit) {
					toast.error('Failed to approve')
					return;
				}

				const contract = new Contract(LendPoolABI, LendPoolAddress)
				contract.setProvider(provider)

				console.log(BigInt(depositNum * 10 ** 18))

				const txData = contract.methods.deposit(
					USDTAddress,
					BigInt(depositNum * 10 ** 18),
					account,
					0
				).encodeABI()

				const result = await provider.request({
					method: 'eth_sendTransaction',
					params: [{
						from: account,
						to: LendPoolAddress,
						gas: '3000000',
						data: txData,
						value: 0,
					}]
				})

				toast.success(`Deposited: ${result}`)

			}catch(err) {
				console.error(err)
				toast.error(`Failed to deposit: ${err.message}`)
			}
		}
	}

	const withdraw = async () => {
		if(!account || !provider) {
			toast.error('Not connected')
			
			return;
		}else {
			try {
				if(Number.isNaN(withdrawNum)) {
					toast.error('Invalid number')
					return;
				}

				const maxWithdraw = await getMaxWithdraw()

				if(maxWithdraw === undefined || maxWithdraw === null) {
					toast.error('Failed to get max withdraw')
					return;
				}

				if(BigInt(maxWithdraw) < BigInt(withdrawNum * 10 ** 18)) {
					toast.error('Insufficient balance')
					return;
				}

				const contract = new Contract(LendPoolABI, LendPoolAddress)
				contract.setProvider(provider)

				const txData = contract.methods.withdraw(
					USDTAddress,
					BigInt(withdrawNum * 10 ** 18),
					account
				).encodeABI()

				const result = await provider.request({
					method: 'eth_sendTransaction',
					params: [{
						from: account,
						to: LendPoolAddress,
						gas: '3000000',
						data: txData,
						value: 0,
					}]
				})

				toast.success(`Withdrew: ${result}`)

			}catch(err) {
				console.error(err)
				toast.error(`Failed to withdraw: ${err.message}`)
			}
		}
	}

	return (
		<div className="App">
			<Toaster />
			<button onClick={() => {
				if(!account) {
					sdk?.connect().then(() => {
						provider?.request({
							method: 'wallet_addEthereumChain',
							params: [
								{
									chainId: '0xcc',
									chainName: 'opBNB Mainnet',
									blockExplorerUrls: ['https://mainnet.opbnbscan.com/'],
									nativeCurrency: { symbol: 'BNB', decimals: 18 },
									rpcUrls: ['https://opbnb-mainnet-rpc.bnbchain.org/'],
								}
							]
						})
					})
				} else {
					toast.warning('Already connected')
				}
			}}>
				{account ? account : "Connect Wallet"}
			</button>

			<div style={{
				marginTop: 50
			}}></div>

			deposit amount
			<input
				type="number"
				value={depositNum}
				onChange={(e) => setDepositNum(Number(e.target.value))}
			/>

			<button onClick={deposit}>
				Deposit
			</button>

			<div style={{
				marginTop: 50
			}}></div>

			withdraw amount
			<input
				type="number"
				value={withdrawNum}
				onChange={(e) => setWithdrawNum(Number(e.target.value))}
			/>

			<button onClick={withdraw}>
				Withdraw
			</button>
		</div>
	);
}

export default App;
