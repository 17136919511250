import React from 'react'
import ReactDOM from 'react-dom/client'
import { MetaMaskProvider } from '@metamask/sdk-react'
import App from './App.tsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <MetaMaskProvider 
      sdkOptions={{
        checkInstallationImmediately: false,
        dappMetadata: {
          name: 'anome admin',
        }
      }}
    >
      <App />
    </MetaMaskProvider>
  </React.StrictMode>,
)
